const getLayout = function(vehicle, pageContext) {
  if (vehicle.groups.bestDeal && pageContext !== 'partner' && pageContext !== 'business') {
    return 'best-deal'
  }

  if (pageContext !== 'home' && pageContext !== 'partner') {
    return pageContext
  }

  return 'economy'
}

export { getLayout }
