<template>
<div class="px-4 md:px-16 lg:px-32">
  <div class="text-center" v-if="isLoading">
    <span class="spinner" />
  </div>

  <div v-if="withFilter && !isLoading" class="flex mo:flex-col sm:items-start mb-4 sm:mb-8 filter">
    <div class="flex">
      <div class="sm:mr-8 mo:mb-2 sm:w-64 mo:w-full">
        <div class="flex">
          <div class="filter-push flex-grow w-1/2">
            <input type="radio" v-model="vehicleFilter.automatic" :value="true" id="featureAutomatic" @click="resetTransmissionIf(true)">
            <label for="featureAutomatic">{{ labels.filter['transmission-automatic-short'] }}</label>
          </div>
          <div class="filter-push -ml-px flex-grow w-1/2">
            <input type="radio" v-model="vehicleFilter.automatic" :value="false" id="featureManual" @click="resetTransmissionIf(false)">
            <label for="featureManual">{{ labels.filter['transmission-manual-short'] }}</label>
          </div>
        </div>
        <div class="w-full">
          <multiselect class="mb-1"
            v-model="vehicleFilter.make"
            :placeholder="labels.filter['makes']"
            :options="options.make"
            :option-height="35"
            :multiple="true"
            :searchable="false"
            :hide-selected="true"
            :show-labels="false"
            :close-on-select="false" />
        </div>
        <div class="w-full">
          <multiselect class="mb-1"
            v-model="vehicleFilter.sortBy"
            :placeholder="labels.filter['sort-placeholder']"
            :options="options.sortBy"
            track-by="label"
            :custom-label="customSortLabel"
            :option-height="35"
            :multiple="false"
            :searchable="false"
            :hide-selected="true"
            :show-labels="false"
            :close-on-select="true"
            @select="onSortChange" />
        </div>
      </div>
      <div class="filter-sep mo:hidden" />
    </div>
    <div class="flex flex-wrap items-start align-top sm:ml-8">
      <div class="filter-push mr-2"
        v-for="classification in options.classification"
        :key="classification">
        <input type="checkbox" v-model="vehicleFilter.classification" :value="classification" :id="classification">
        <label :for="classification">{{ labels.classification[classification] }}</label>
      </div>
    </div>
    <div class="w-32 flex-none mo:text-center mo:h-6 sm:text-right ml-auto mo:mr-auto">
      <transition name="fade">
        <button class="border-b border-red-500 text-red-500 text-xs text-right"
          v-if="filterActive"
          @click="clearFilter">
            {{ labels.filter['reset-selection'] }}
        </button>
      </transition>
    </div>
  </div>

  <div class="text-center" v-if="!isLoading && filteredVehicles.length == 0">
    <span class="inline-block text-md py-32">{{ labels.filter['no-results'] }}</span>
  </div>

  <ul v-else class="flex flex-wrap -mx-4">
    <li class="w-full px-4 pb-5 text-gray-600  sm:w-1/2 xl:w-1/3"
      v-for="vehicle in paginatedVehicles"
      :key="vehicle.id">
      <Vehicle
        :vehicle="vehicle"
        :can-add-more="canAddMore"
        :selected="isSelected(vehicle)"
        :page-context="pageContext"
        :discount="discount"
        :discount-enabled="discountEnabled"
        :discount-name="discountName"
        :labels="labels"
        @goto-inquiry="gotoInquiry"
        @toggle-vehicle="toggleVehicle" />
    </li>
  </ul>

  <slot />

  <div class="text-center mt-8 sm:mt-16" v-show="filteredVehicles.length > 0">
    <div class="mb-4">{{ paginatedVehicles.length }} / {{ filteredVehicles.length }}</div>
    <button @click="showMore()" class="show-more-btn text-gray-500" v-show="morePagesAvailable"><ArrowDown class="fill-current" /></button>
  </div>

  <transition name="slide">
    <div class="max-reached bg-white shadow fixed right-0 bottom-0 mb-64 px-6 py-6 z-10"
      v-if="maxSelectionMessageActive">
      <p class="uppercase text-red-500 leading-tight max-w-xxs">{{ labels['max-vehicles-selected'] }}</p>
    </div>
  </transition>
</div>
</template>

<script>
import axios from 'axios'
import tracking from '../util/tracking'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import Vehicle from './fleet/Vehicle'
import ArrowDown from '@/assets/svg/icons/arrow-h-w.svg?inline'

import { getLayout } from '@/util/layout-calculator'
import { getPrice, getPartnerPrice } from '@/util/price-calculator'

const VEHICLES_PER_PAGE = 6

export default {
  components: {
    Vehicle,
    ArrowDown,
    Multiselect
  },
  props: {
    pageContext: {
      type: String,
      default: 'all',
      validator: value => {
        return ['best-deal', 'business', 'economy', 'home', 'partner'].indexOf(value) !== -1
      }
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    withFilter: {
      type: Boolean,
      default: true
    },
    discount: {
      type: Number,
      default: 0,
    },
    discountEnabled: {
      type: Boolean,
      default: false
    },
    discountName: {
      type: String,
      default: 'Rabatt'
    },
    preselection: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isLoading: true,
      maxSelectionMessageActive: false,
      vehicles: [],
      vehicleFilter: {
        make: [],
        classification: [],
        automatic: null,
        sortBy: ''
      },
      options: {
        make: [],
        classification: [],
        sortBy: [
          { label: 'sort-reset', property: '', descending: false },
          { label: 'sort-price-ascending', property: 'price', descending: false },
          { label: 'sort-price-descending', property: 'price', descending: true },
          { label: 'sort-make-ascending', property: 'make', descending: false },
          { label: 'sort-make-descending', property: 'make', descending: true }
        ]
      },
      page: 1
    }
  },
  computed: {
    ...mapGetters([
      'selectionCount',
      'canAddMore'
    ]),
    selectedVehicles() {
      return this.$store.state.selectedVehicles
    },
    filteredVehicles() {
      let selection = this.vehicles

      // Make
      if (this.vehicleFilter.make.length) {
        selection = selection.filter(item => {
          return item.make.some(m => {
            return this.vehicleFilter.make.includes(m)
          })
        })
      }

      // Transmission
      if (this.vehicleFilter.automatic !== null) {
        selection = selection.filter(item => {
          return this.vehicleFilter.automatic == item.features.automatic
        })
      }

      // Classification
      if (this.vehicleFilter.classification.length) {
        selection = selection.filter(item => {
          return item.classification.some(c => {
            return this.vehicleFilter.classification.includes(c)
          })
        })
      }

      let result = 0
      const sortBy = this.vehicleFilter.sortBy
      switch (sortBy.property) {
        case 'make':
          selection.sort((a, b) => {
            result = a.make[0].localeCompare(b.make[0])
            return sortBy.descending ? result *= -1 : result
          })
          break
        case 'price':
          selection.sort((a, b) => {
            if (a.price < b.price) {
              result = -1
            }
            if (a.price > b.price) {
              result = 1
            }
            return sortBy.descending ? result *= -1 : result
          })
          break
        default:
          selection.sort((a, b) => {
            if (a.defaultSort < b.defaultSort) {
              return -1
            }
            if (a.defaultSort > b.defaultSort) {
              return 1
            }
            return 0
          })
      }

      return selection
    },
    paginatedVehicles() {
      return this.filteredVehicles.slice(0, this.page * VEHICLES_PER_PAGE)
    },
    morePagesAvailable() {
      return this.page < Math.ceil(this.filteredVehicles.length / VEHICLES_PER_PAGE)
    },
    filterActive() {
      return this.vehicleFilter.make.length > 0
        || this.vehicleFilter.classification.length > 0
        || this.vehicleFilter.automatic != null
    }
  },
  created() {
    const src = this.pageContext == 'partner' ? 'home' : this.pageContext
    const API_FLEET_URL = `/api/fleet/${src}.json`
    axios.get(API_FLEET_URL)
      .then(result => {
        if (result.data && result.data.vehicles) {
          this.vehicles = result.data.vehicles
          this.populateOptions()
          this.prepopulateFilter()
          this.isLoading = false
        } else {
          throw new Error('Invalid API response!')
        }
      })
      .catch(error => {
        console.log('error:', error)
      })
  },
  methods: {
    toggleVehicle(vehicle) {
      if (this.isSelected(vehicle)) {
        this.$store.commit('removeVehicle', vehicle)
      } else {
        if (this.canAddMore) {
          this.$store.commit('addVehicle', vehicle)
        } else {
          this.showMessage()
        }
      }
    },
    isSelected(vehicle) {
      return this.$store.state.selectedVehicles.hasOwnProperty(vehicle.id)
    },
    showMessage() {
      if (this.maxSelectionMessageActive) {
        return
      }
      this.maxSelectionMessageActive = true
      setTimeout(() => {
        this.maxSelectionMessageActive = false
      }, 5000)
    },
    gotoInquiry(vehicle = null) {
      if (vehicle && this.canAddMore) {
        this.$store.commit('addVehicle', vehicle)
      }

      if (vehicle) {
        tracking.pushEvent({
          'car_type': vehicle.hertzId,
          'eventAction': 'Auswahl',
          'eventCategory': 'Fahrzeug',
          'eventLabel': vehicle.title,
          'event': 'event'
        })
      }

      const $a = document.querySelector('a[name="inquiry"]')
      window.scrollTo({ top: $a.offsetTop, behavior: 'smooth' })
    },
    showMore() {
      this.page++
    },
    populateOptions() {
      this.vehicles.forEach((v, i) => {
        const layout = getLayout(v, this.pageContext)
        v.defaultSort = i
        v.price = this.pageContext !== 'partner'
          ? getPrice(v, layout)
          : getPartnerPrice(v, this.discountEnabled ? this.discount : false)
      })

      let makes = []
      this.vehicles.forEach(v => makes.push(...v.make))
      this.options.make = makes.filter(function(item, index) {
        return makes.indexOf(item) >= index
      }).sort()

      let classifications = []
      this.vehicles.forEach(v => classifications.push(...v.classification))
      this.options.classification = classifications.filter(function(item, index) {
        return classifications.indexOf(item) >= index
      }).sort()
    },
    prepopulateFilter() {
      if (this.preselection.hasOwnProperty('make')) {
        this.vehicleFilter.make = this.preselection.make
      }
      if (this.preselection.hasOwnProperty('classification')) {
        this.vehicleFilter.classification = this.preselection.classification
      }
      if (this.preselection.hasOwnProperty('automatic')) {
        this.vehicleFilter.automatic = this.preselection.automatic
      }
    },
    clearFilter() {
      this.vehicleFilter.classification = []
      this.vehicleFilter.make = []
      this.vehicleFilter.automatic = null
    },
    resetTransmissionIf(val) {
      if (val == this.vehicleFilter.automatic) {
        this.vehicleFilter.automatic = null
      } else {
        this.vehicleFilter.automatic = val
      }
    },
    onSortChange() {
      this.$nextTick(() => {
        if (this.vehicleFilter.sortBy.value === '') {
         this.vehicleFilter.sortBy = []
        }
      })
    },
    customSortLabel({ label }) {
      return this.labels.filter[label]
    }
  },
  watch: {
    vehicleFilter: {
      deep: true,
      handler() {
        this.page = 1
      }
    }
  }
}
</script>

<style>

.fleet:before,
.fleet:after {
  @apply h-32 block w-full;
  content: '';
  transform: skewY(-4deg) translate(0, -50%);
  background-color: inherit;
}

@screen mo {
  .fleet:before,
  .fleet:after {
    @apply h-16;
  }
}

.fleet:before {
  @apply mt-32;
}

.fleet:after {
  @apply mb-16;
  transform: skewY(-4deg) translate(0, 50%);
}

.fleet-item {
  @apply text-center bg-white shadow select-none;
  border: 1px solid #cecece;
  transition: all 0.1s ease;
}

.fleet-item--selected {
  @apply bg-gray-400 shadow-lg;
}

.fleet-item__toggle {
  @apply cursor-pointer;

  & label {
    @apply block text-xs bg-gray-200 border border-gray-500;
    @apply bg-no-repeat bg-center;
    border-radius: .35rem;
    width: 1.7rem;
    height: 1.7rem;

    background-image: url(../assets/svg/icons/checkmark.svg);
    background-size: 0%;
  }

  &.fleet-item__toggle--disabled label {
    opacity: 0.4;
  }

  & input {
    @apply hidden;
  }
}

.fleet-item--selected .fleet-item__toggle label {
  background-size: 80%;
}

.filter {
  @apply text-gray-700 text-sm select-none relative;
}

.filter-sep {
  @apply block w-px mb-1;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAD0lEQVQImWNgYGBoYEAGAAcSAIEdYHRDAAAAAElFTkSuQmCC);
  opacity: 0.8;
}

.filter-push {
  @apply block flex-none mb-1;
  border: 1px solid theme('colors.gray.400');

  & input {
    display: none;
  }

  & label {
    @apply inline-block text-center w-full h-full px-4 py-1 pb-2 cursor-pointer;
    background-color: white;
    transition: all 0.1s;
  }

  & input:checked {
    & + label {
      @apply text-black;
      background-color: #eee;
    }
  }
}

</style>
