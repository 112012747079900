const getDiscountedPrice = function(price, discount) {
  return Math.round(100 - discount) / 100 * price
}

const getPrice = function(vehicle, layout) {
  switch (layout) {
    case 'business':
      return vehicle.prices.business
    case 'best-deal':
      return vehicle.prices.bestDeal !== 0 ? vehicle.prices.bestDeal : vehicle.prices.economy
    case 'economy':
      return vehicle.prices.economy
  }

  return false
}

const getPartnerPrice = function(vehicle, discount = false) {
  const isBestDealAvailable = vehicle.groups.bestDeal
      && vehicle.prices.bestDeal
      && vehicle.prices.bestDeal !== 0
  let price

  if (discount) {
    price = getDiscountedPrice(vehicle.prices.economy, discount)
    if (isBestDealAvailable && vehicle.prices.bestDeal < price) {
      price = vehicle.prices.bestDeal
    }
  } else {
    price = isBestDealAvailable ? vehicle.prices.bestDeal : vehicle.prices.economy
  }

  return price
}

const getStrikethroughPrice = function(vehicle, layout) {
  return layout == 'best-deal' && vehicle.prices.bestDeal !== 0
    ? vehicle.prices.economy
    : false
}

const getPartnerStrikethroughPrice = function(vehicle, discount = false) {
  const partnerPrice = getPartnerPrice(vehicle, discount)
  return partnerPrice < vehicle.prices.economy
    ? vehicle.prices.economy
    : false
}

export {
  getPrice,
  getPartnerPrice,
  getStrikethroughPrice,
  getPartnerStrikethroughPrice,
  getDiscountedPrice
}
