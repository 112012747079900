<template>
  <article :class="[ 'fleet-item', { 'fleet-item--selected': selected }]">
    <header class="relative pt-3 mb-4">
      <div class="flex flex-row w-full items-start justify-between  absolute top-0 x px-2 pt-2">
        <Tooltip class="-mx-1 -mt-1">
          <p v-if="layout != 'economy'">
            <strong>{{ labels.attributes.deductible }}</strong>: CHF {{ vehicle.deductible }}
          </p>
          <p>
            <strong>{{ labels['model-guarantee'] }}</strong>: {{ labels['model-guarantee-info'] }}
          </p>
        </Tooltip>
        <span :class="['fleet-item__toggle', { 'fleet-item__toggle--disabled': !selected && !canAddMore }]">
          <label>
            <input type="checkbox"
              @click="$emit('toggle-vehicle', vehicle)"
              :checked="selected">
          </label>
        </span>
      </div>
      <h3 class="text-lg text-center font-bold px-10 leading-tight">{{ vehicle.title }}</h3>
    </header>
    <div>
      <div class="px-16" @click="$emit('toggle-vehicle', vehicle)">
        <div class="aspect-ratio-3/2 relative">
          <img class="absolute w-full inset-0 lazyload"
            v-if="vehicle.imageSrcSet"
            data-sizes="auto"
            :data-srcset="vehicle.imageSrcSet"
            :data-src="vehicle.imageSrc"
            :alt="vehicle.title">
        </div>
      </div>
      <div class="mb-8">
        <template v-if="formattedStrikethroughPrice && pageContext !== 'partner'">
          <s class="text-sm strikethru">
            CHF {{ formattedStrikethroughPrice[0] }}.<span class="text-2xs"><sup>{{ formattedStrikethroughPrice[1] }}</sup> / Monat</span>
          </s>
          <div class="text-xl font-bold">
            CHF {{ formattedPrice[0] }}.<span><sup class="text-xs">{{ formattedPrice[1] }}</sup> / Monat</span>
          </div>
        </template>
      </div>
      <div class="px-4 lg:px-5 pb-4 -mt-2">
        <div class="lg:px-5" :class="{ 'mb-5': pageContext === 'partner', 'mb-12': pageContext !== 'partner' }">
          <hr class="h-1 bg-dashed">
          <ul class="flex flex-wrap text-left px-1 text-sm leading-tight">
            <li class="w-1/2 mb-4 flex px-1" v-for="attribute in attributes.slice(0,6)" :key="attribute.id">
              <template v-if="pageContext != 'best-deal' && attribute.id != 'no-deposit'">
                <svg class="h-4 w-4 mr-2 mt-px flex-none"><use :xlink:href="`#${attribute.id}--sprite`" /></svg>
              </template>
              <template v-else>
                <svg class="h-4 w-4 mr-2 mt-1 flex-none"><use xlink:href="#checkmark--sprite" /></svg>
              </template>
              <span v-html="attribute.value" />
            </li>
          </ul>
          <SlideUpDown :active="isOpen" :duration="300">
            <ul class="flex flex-wrap text-left px-1 text-sm leading-tight">
              <li class="w-1/2 mb-4 flex px-1" v-for="attribute in attributes.slice(6)" :key="attribute.id">
                <template v-if="pageContext != 'best-deal' && attribute.id != 'no-deposit'">
                  <svg class="h-4 w-4 mr-2 mt-px flex-none"><use :xlink:href="`#${attribute.id}--sprite`" /></svg>
                </template>
                <template v-else>
                  <svg class="h-4 w-4 mr-2 mt-1 flex-none"><use xlink:href="#checkmark--sprite" /></svg>
                </template>
                <span v-html="attribute.value" />
              </li>
            </ul>
          </SlideUpDown>
          <div class="text-center">
            <button class="details-button" @click="isOpen = !isOpen" :class="{ 'is-open': isOpen }">
              <svg><use xlink:href="#arrow-h-w--sprite" /></svg>
            </button>
          </div>
          <hr class="h-1 bg-dashed">
          <template v-if="!formattedStrikethroughPrice && pageContext !== 'partner'">
            <div class="text-xl font-bold">
              CHF {{ formattedPrice[0] }}.<span><sup class="text-xs">{{ formattedPrice[1] }}</sup> / {{ labels['month'] }}</span>
            </div>
          </template>

          <div v-if="pageContext === 'partner'" class="leading-loose text-real-black">
            <div v-if="formattedStrikethroughPrice" class="text-xl font-bold">
              <template v-if="price == vehicle.prices.bestDeal">
                <s class="strikethru">
                  CHF {{ formattedStrikethroughPrice[0] }}.<sup class="text-xs">{{ formattedStrikethroughPrice[1] }}</sup><span class="text-sm"> / {{ labels['month'] }}</span>
                </s>
              </template>
              <template v-else>
                CHF {{ formattedStrikethroughPrice[0] }}.<sup class="text-xs">{{ formattedStrikethroughPrice[1] }}</sup><span class="text-sm"> / {{ labels['month'] }}</span>
              </template>
            </div>

            <div class="text-xl font-bold">
              <template v-if="price == vehicle.prices.bestDeal">
                <span class="text-hertz-yellow bg-real-black px-2 pb-1 rounded rounded-lg">Best Deal</span>
              </template>
              <template v-if="price != vehicle.prices.bestDeal && discountEnabled && discount > 0">
                {{ discountName }} <span class="text-hertz-yellow bg-real-black px-2 pb-1 rounded rounded-lg">-{{ discount }}%</span>
              </template>
            </div>
          </div>
        </div>

        <div class="group relative">
          <div v-if="pageContext === 'partner'" class="group-hover:hidden bg-real-black text-hertz-yellow flex justify-center items-center absolute inset-0 touch:static touch:mb-1 touch:h-10">
            <div class="text-xl font-bold leading-none">
              CHF {{ formattedPrice[0] }}.<sup class="text-xs">{{ formattedPrice[1] }}</sup><span class="text-sm"> / {{ labels['month'] }}</span>
            </div>
          </div>
          <a href="#inquiry"
            :class="{ 'no-touch:opacity-0 group-hover:opacity-100 button--no-trans': pageContext === 'partner' }"
            class="button button--full"
            @click.prevent="$emit('goto-inquiry', vehicle)">
            {{ labels['make-inquiry'] }}
          </a>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import '@/assets/svg/icons/4x4.svg?sprite'
import '@/assets/svg/icons/all-season.svg?sprite'
import '@/assets/svg/icons/complete-coverage.svg?sprite'
import '@/assets/svg/icons/deductible.svg?sprite'
import '@/assets/svg/icons/gps.svg?sprite'
import '@/assets/svg/icons/maintenance.svg?sprite'
import '@/assets/svg/icons/mileage.svg?sprite'
import '@/assets/svg/icons/motorway-sticker.svg?sprite'
import '@/assets/svg/icons/taxes.svg?sprite'
import '@/assets/svg/icons/transmission.svg?sprite'
import '@/assets/svg/icons/checkmark.svg?sprite'
import '@/assets/svg/icons/arrow-h-w.svg?sprite'

import Tooltip from './Tooltip'
import { getLayout } from '@/util/layout-calculator'
import {
  getDiscountedPrice,
  getPrice,
  getPartnerPrice,
  getStrikethroughPrice,
  getPartnerStrikethroughPrice
} from '@/util/price-calculator'

const ATTRIBUTES = {
  'best-deal': ['complete-coverage', 'mileage', 'maintenance', 'all-season', 'transmission', '4x4', 'taxes', 'deductible', 'motorway-sticker', 'gps', 'no-deposit'],
  'business': ['transmission', '4x4', 'mileage', 'complete-coverage', 'all-season', 'maintenance',  'gps', 'deductible', 'taxes', 'motorway-sticker', 'no-deposit'],
  'economy': ['maintenance', 'all-season', 'complete-coverage', 'mileage', 'transmission', '4x4', 'deductible', 'taxes', 'gps', 'motorway-sticker', 'no-deposit']
}

export default {
  components: {
    Tooltip
  },
  props: {
    vehicle: {
      type: Object,
      default() {
        return {}
      }
    },
    pageContext: {
      type: String,
      default: 'normal'
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    canAddMore: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Number,
      default: 0
    },
    discountEnabled: {
      type: Boolean,
      default: false
    },
    discountName: {
      type: String,
      default: 'Rabatt'
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    layout() {
      return getLayout(this.vehicle, this.pageContext)
    },
    price() {
      return this.pageContext !== 'partner'
        ? getPrice(this.vehicle, this.layout)
        : getPartnerPrice(this.vehicle, this.discountEnabled ? this.discount : false)
    },
    strikethroughPrice() {
      return this.pageContext !== 'partner'
        ? getStrikethroughPrice(this.vehicle, this.layout)
        : getPartnerStrikethroughPrice(this.vehicle, this.discountEnabled ? this.discount : false)
    },
    formattedPrice() {
      return this.price
        ? this.formatPrice(this.price)
        : false
    },
    formattedStrikethroughPrice() {
      return this.strikethroughPrice
        ? this.formatPrice(this.strikethroughPrice)
        : false
    },
    isBestDealBetterThanDiscount() {
      // Best deal price is lower than discounted price
      return (
        this.pageContext === 'partner'
        && this.discount > 0
        && this.vehicle.prices.bestDeal != 0
        && this.vehicle.prices.bestDeal <= getDiscountedPrice(this.vehicle.prices, this.discount)
      )
    },
    attributes() {
      const attributeSet = []
      const attributeList = ATTRIBUTES[this.layout]

      for (let i = 0; i < attributeList.length; i++) {
        let attribute = attributeList[i]
        let attributeValue = false
        switch (attribute) {
          case 'gps':
            attributeValue = this.vehicle.features.gps ? this.labels.attributes.gps : false
            break
          case '4x4':
            attributeValue = this.vehicle.features.fwd ? this.labels.attributes['4x4'] : false
            break
          case 'transmission':
            attributeValue = this.vehicle.features.automatic
              ? this.labels.attributes['transmission-automatic']
              : this.labels.attributes['transmission-manual']
            break
          case 'deductible':
            attributeValue = 'CHF ' + this.vehicle.deductible + ' ' + this.labels.attributes['deductible']
            break
          case 'mileage':
            attributeValue = '4000 ' + this.labels.attributes['mileage']
            break
          case 'complete-coverage':
            attributeValue = this.labels.attributes['complete-coverage']
            break
          case 'all-season':
            attributeValue = this.labels.attributes['all-season']
            break
          case 'maintenance':
            attributeValue = this.labels.attributes['maintenance']
            break
          case 'taxes':
            attributeValue = this.labels.attributes['taxes']
            break
          case 'motorway-sticker':
            attributeValue = this.labels.attributes['motorway-sticker']
            break
          case 'no-deposit':
            if (attributeSet.length < 10) {
              attributeValue = this.labels.attributes['no-deposit']
            }
            break
        }
        if (attributeValue !== false) {
          attributeSet.push({ id: attribute, value: attributeValue })
        }
      }
      return attributeSet
    }
  },
  methods: {
    formatPrice(price) {
      return price.toFixed(2).toString().split('.')
    }
  }

}
</script>

<style scoped>
.details-button {
  @apply bg-blue-500 p-2 rounded-full relative cursor-pointer;

  & > svg {
    @apply w-3 h-3 inset-0;
    transform: translate3d(0, 1px, 0) rotate(90deg);
    transition: transform 0.3s ease;
  }

  &.is-open > svg {
    transform: translate3d(0, 0, 0) rotate(-90deg);
  }
}
</style>
