<template>
  <div class="swiper-container  v-cloak">
    <slot name="before-wrapper" />
    <div class="swiper-wrapper">
        <slot />
    </div>
    <div
      v-if="defaultPagination"
      class="swiper-pagination" />
    <div
      v-if="defaultScrollbars"
      class="swiper-scrollbar" />
    <div
      v-if="defaultNavigation"
      class="swiper-button-next" />
    <div
      v-if="defaultNavigation"
      class="swiper-button-prev" />
    <slot name="after-wrapper" />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { Navigation, Autoplay, Pagination, Swiper } from 'swiper/dist/js/swiper.esm.js'

Swiper.use([Pagination, Autoplay, Navigation])

export default {
  name: 'SwiperSlider',
  props: {
    options: { type: Object, required: true },
  },

  computed: {
    defaultPagination() {
      return this.options.pagination && this.options.pagination.el == '.swiper-pagination'
    },
    defaultScrollbars() {
      return this.options.scrollbar && !this.options.scrollbar.el
    },
    defaultNavigation() {
      const { navigation: nav } = this.options
      return nav && (nav.nextEl == '.swiper-button-next' && nav.prevEl == '.swiper-button-prev')
    },
  },

  watch: {
    options() {
      this.recreateSwiper()
    },
  },

  mounted() {
    this.recreateSwiper()
  },

  updated() {
    this.swiper.update()
  },

  beforeDestroy() {
    if (this.swiper) this.swiper.destroy()
  },

  methods: {
    control(swiper) {
      this.controlled = swiper
      this.recreateSwiper()
    },

    recreateSwiper() {
      if (this.swiper) this.swiper.destroy()
      const o = Object.assign({}, {
        preloadImages: false,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        a11y: true,
        navigation: {}
      },
      this.options,
      {
        controller: {
          control: this.controlled,
        },
      })

      this.swiper = new Swiper(this.$el, o)
      this.$emit('update:swiper', this.swiper)
    },
  },
}
</script>

<style>
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 32px;

  & .swiper-pagination-bullet {
    background: theme('colors.hertz-yellow');
    opacity: 0.4;
    width: 14px;
    height: 14px;
    margin: 0 10px;
  }

  & .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

.swiper-slide {
  box-sizing: border-box;
}

.swiper-button-next.swiper-button-next,
.swiper-button-prev.swiper-button-prev {
  background-image: url(~@/assets/svg/icons/arrow-h-w.svg);
  background-color: rgba(0, 0, 0, 0.2);
  background-size: 40%;
  width: 46px;
  height: 53px;
}

.stage .swiper-button-next.swiper-button-next,
.stage .swiper-button-prev.swiper-button-prev {
  top: 28vw;
}

.swiper-container.simple {
  & .swiper-button-next,
  & .swiper-button-prev {
    background-image: url(~@/assets/svg/icons/arrow-h.svg);
    background-color: transparent;
  }
}

.swiper-button-next.swiper-button-next {
  right: 0;
}

.swiper-button-prev.swiper-button-prev {
  transform: scaleX(-1);
  left: 0;
}

@screen md {
  .stage .swiper-button-next.swiper-button-next,
  .stage .swiper-button-prev.swiper-button-prev {
    top: 50%;
  }
}

@screen mo {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 57vw;

    & .swiper-pagination-bullet {
      background: theme('colors.hertz-yellow');
      width: 10px;
      height: 10px;
      margin: 0 7px;
    }
  }

  .swiper-button-prev.swiper-button-prev,
  .swiper-button-next.swiper-button-next {
    width: 30px;
    height: 36px;
  }
}
</style>
