class TrackingHelper {
  getClientId() {
    if (window.dataLayer && window.dataLayer.length) {
      for (let i = 0; i < window.dataLayer.length; i++) {
        let o = window.dataLayer[i]
        if (o.hasOwnProperty('event') && o.event == 'cidReady' && o.hasOwnProperty('CID')) {
          return o.CID
        }
      }
    }
    return false
  }

  pushEvent(data) {
    if (window.dataLayer) {
      window.dataLayer.push(data)
    }
  }
}

export default new TrackingHelper()
