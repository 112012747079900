<template>
  <div>
    <label class="block mb-1" :for="name">
      {{ label }}<span v-show="isRequired">*</span>
    </label>
    <input
      v-model="validation.$model"
      :placeholder="placeholder"
      :id="name"
      :class="{
        'invalid': validation.$error,
        'valid': !validation.$error
      }"
      :tabindex="tabindex"
      :readonly="readonly"
      type="text"
      class="block w-full"
    >
    <template v-if="validation.$error">
      <div v-for="(key, val) in validation.$params">
        <span
          v-if="!validation[val]"
          class="inquiry-form__error"
        >
          {{ messages[val] }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    label: String,
    placeholder: String,
    validation: Object,
    messages: Object,
    tabindex: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRequired() {
      return this.validation.$params.hasOwnProperty('required')
    }
  }
}
</script>
