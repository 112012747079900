<template>
<nav class="mo:w-full mo:text-center">
  <ul class="sm:flex">
    <li
      v-for="(item, index) in sanitizedItems"
      :key="index"
      class="px-4 mo:py-2 mo:border-gray-500 mo:border-b"
      :class="{
        'mo:border-t': index === 0,
        'sm:mr-0': index === items.length - 1 }">
      <a
        class="text-gray-600 uppercase hover:text-black"
        :href="item.href"
        @click="item.isAnchor ? goto($event, item.href.substring(1)) : null">
        {{ item.label }}
      </a>
    </li>
  </ul>
</nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    sanitizedItems() {
      return this.items.map(item => {
        if (!item.isAnchor) {
          return item
        }

        const targetExists = document.querySelector(`[name="${item.href.substring(1)}"]`) !== null
        if (targetExists) {
          return item
        }

        return {
          href: document.querySelector('link[rel="home"]').getAttribute('href'),
          label: item.label,
          isAnchor: false
        }
      })
    }
  },
  mounted() {
    const $els = document.querySelectorAll('[data-smooth]')
    for (let i = 0; i < $els.length; i++) {
      let $el = $els[i]
      let section = $el.getAttribute('href').substring(1)
      $el.addEventListener('click', event => {
        event.preventDefault()
        this.goto(section)
      })
    }
  },
  methods: {
    goto(event, section) {
      event.preventDefault()

      if (section == 'faq') {
        this.$root.$refs.faq.closeAll()
        this.$root.$refs.faq.open(0)
      }
      const $a = document.querySelector(`a[name="${section}"]`)
      window.scrollTo({ top: $a.getBoundingClientRect().top, behavior: 'smooth' })

      this.$store.commit('closeMobileNav')
    }
  }
}
</script>
